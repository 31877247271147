<template>
    <div class="box">
        <div class="search">
           
            <el-form label-width="120px" label-height="60px">
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="用户ID：" prop="contactName">
                      <el-input
                        v-model="queryInfo.name"
                        placeholder="请输入"
                        clearable
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="用户昵称：" prop="contactName">
                      <el-input
                        v-model="queryInfo.name"
                        placeholder="请输入"
                        clearable
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="用户手机号：" prop="contactName">
                      <el-input
                        v-model="queryInfo.name"
                        placeholder="请输入"
                        clearable
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="充值时间：" prop="contactName">
                      <el-input
                        v-model="queryInfo.name"
                        placeholder="请输入"
                        clearable
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  
                  <el-col :span="8" style="display: flex; justify-content: right;">
                    <el-button @click="reset" icon="el-icon-refresh-left">重置</el-button>
                    <el-button type="primary" @click="search" icon="el-icon-search"
                      >搜索</el-button
                    >
                  </el-col>
                </el-row>
              
            </el-form>
        </div>
        <div class="tab">
            <div class="add" style="display: flex;font-size: 12px;color: #409eff;">
                <el-button
                  type="primary"
                  @click="added"
                  >导出</el-button
                >
                <div style="font-size: 14px;">兑换金额 （9999）</div>
                <div style="font-size: 14px;">赠送金额 （9999）</div>
            </div>
            
            <el-table
        :data="tableData"
        style="width: 100%"
        show-summary
        height="calc(100vh - 380px)"
        border
        :header-cell-style="{
          'background-color': '#f2f2f2',
          'text-align': 'center',
          border: '1px solid #ddd',
        }"
      >
        
        
        <el-table-column
          prop="memberId"
          label="用户ID"
          width="200"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="memberName"
          label="用户昵称"
         
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="vipLevel"
          label="会员等级"
         
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="phone"
          label="手机号"
         width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="pointchangeAmount"
          label="兑换金额"
         
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="name"
          label="赠送金额"
         
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="name"
          label="兑换卡号"
         
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="name"
          label="兑换卡密"
         
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="name"
          label="账户余额"
         
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="name"
          label="兑换时间"
         
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="name"
          label="兑换类型"
         
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="name"
          label="兑换方式"
         
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="name"
          label="审核状态"
         
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="name"
          label="审核意见"
         
          align="center"
        >
        </el-table-column>
        
       
        <!-- <el-table-column
          prop=" "
          label="操作"
          width="250"
          fixed="right"
          align="center"
        >
        <template slot-scope="scope">
            <el-button type="text" @click="drawer=true">修改</el-button> 
            <el-button type="text" @click="drawer=true">查看</el-button> 
            <el-button type="text" @click="drawer=true">下架</el-button> 
        </template>
        </el-table-column> -->
  
  
            </el-table>  
            <div class="pagination">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="queryInfo.currPage"
                :page-size="queryInfo.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="count"
              >
              </el-pagination>
            </div>
        </div> 
        
    </div>
    
  </template>
  
  <script>
  export default {
  data() {
    return {
        drawer:false,
        tableData:[],
        total: 0,
        queryInfo: {
          pageSize: 10,
          pageCount: 1,
          currPage: 1,
          condition: {
            recordType:1,
            pointchangeType:3
          }
        },
        form: {},
       
    };
  },
  created () {
    this.getList()
  },
  mounted() {
   
  },
  methods: {
  //获取数据
  getList () {
	  var that = this;
	  that.$http.post("/transaction/list", that.queryInfo).then(function (response) {
        console.log(response.data.data,'response.data.data');
        if (response.data.code == 200) {
          that.total = response.data.data.count;
          that.queryInfo.currPage=response.data.data.currPage
          that.tableData = response.data.data.data;
        }
      });
  },
  // 修改页数大小
  handleSizeChange (val) {
      this.queryInfo.pageSize = val;
      this.getList()
  },

    // 获取当前页面
  handleCurrentChange (val) {
      this.queryInfo.currPage = val;
      this.getList()
  },
  search(){
    this.queryInfo.condition = this.form
    this.getList()
  },
  reset(){
    this.form = {}
    this.queryInfo.condition= {}
    this.getList()
  }
  }
  };
  </script>
  
  <style lang="less" scoped>
  .box {
    .search {
        background-color: #fff;
        box-sizing: border-box;
        padding: 20px 20px 10px 10px;
       
    }
    .tab {
        background-color: #fff;
        margin-top: 20px;
        padding: 20px
    }
    .dia {
      .but {
            display: flex;
            justify-content: space-between;
            padding: 30px 200px;
        }
      .diali {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .liname {
            width: 100px;
        }
        input {
            height: 30px;
        }
      }
      
    }
   
  }
  </style>
  